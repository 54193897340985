/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";

import { ethers } from "ethers"

import Config from "config";

import Connect from "components/connect";
import StayUpdated from "components/stayUpdated";

import useWeb3 from "hooks/useWeb3";

import { useDocument, useCollection } from 'react-firebase-hooks/firestore';
import { getFirestore, doc, collection, updateDoc, setDoc, getDocs, where, query, addDoc, connectFirestoreEmulator, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { initializeApp } from 'firebase/app';

import { Oval } from  'react-loader-spinner'
import dayjs from "dayjs";

let app;
if (process.env.NODE_ENV === "development") {
    app = initializeApp({
        //apiKey: "AIzaSyDrDPeO5NrSnvzc85fDdEY_wx7ZKaiyLLU",
        //authDomain: "slotie-baa44.firebaseapp.com",
        //projectId: "slotie-baa44",
        //storageBucket: "slotie-baa44.appspot.com",
        //messagingSenderId: "620052493222",
        //appId: "1:620052493222:web:96c2f343d1d8e0f2381567"
        projectId:"slotie-test",
        storageBucket:"slotie-test.appspot.com",
        authDomain:"slotie-test.firebaseapp.com",
        apiKey:"AIzaSyCcSSPWl_v2nWG34ohhOFkFXLdUL1rjCyI"
    })
} else {
    app = initializeApp({
        apiKey: "AIzaSyDrDPeO5NrSnvzc85fDdEY_wx7ZKaiyLLU",
        authDomain: "slotie-baa44.firebaseapp.com",
        projectId: "slotie-baa44",
        storageBucket: "slotie-baa44.appspot.com",
        messagingSenderId: "620052493222",
        appId: "1:620052493222:web:96c2f343d1d8e0f2381567"
        //projectId:"slotie-test",
        //storageBucket:"slotie-test.appspot.com",
        //authDomain:"slotie-test.firebaseapp.com",
        //apiKey:"AIzaSyCcSSPWl_v2nWG34ohhOFkFXLdUL1rjCyI"
    })
}

const firestore = getFirestore(app);
const functions = getFunctions(app);

//connectFirestoreEmulator(firestore, 'localhost', 8080);
//connectFunctionsEmulator(functions, 'localhost', 5001);

function getArgsFromEvent(contract, receipt, eventName, targetArg) {
    const requestIds = [];
    const logs = receipt.logs;
    const contractInterface = contract.interface
    
    for (const log_key in logs)
    {
        try {
            const parsed = contractInterface.parseLog(logs[log_key]);
            const name = parsed.name;

            if (name === eventName) {
                requestIds.push(parsed.args[targetArg]);
            }
        } catch (e) {
            continue;
        }
        
    }

    return requestIds;
}

export const validateDiscord = (discord) => {
    return String(discord)
      .toLowerCase()
      .match(/^.{3,32}#[0-9]{4}$/);
};

export const validatePokerClub = (poker) => {
    return poker !== "" && typeof poker === "string"
}

const Home = () => {
    const [
        provider,
        signer,
        address,
        contractWatts,
        contractPoker,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect
    ] = useWeb3();

    const [discordName, setDiscordName] = useState("")
    const [pokerclubName, setPokerclubName] = useState("")

    const [walletDoc, setWalletDoc] = useState();

    const [isTx, setIsTx] = useState();
    const [txHash, setTxHash] = useState();
    const [txError, setTxError] = useState();

    const [isSavingDiscord, setIsSavingDiscord] = useState();
    const [isSavingPoker, setIsSavingPoker] = useState();
    const [saveError, setSaveError] = useState();

    const [codes, setCodes] = useState([])

    const getExpiry = createdAt => {
        if (createdAt && createdAt.seconds) {
            let timestamp = createdAt.seconds
            let expirationTime = timestamp + 72 * 2600
            return dayjs.unix(expirationTime).format("MMM D HH:mm:ss A")
        }        
    }

    const getCodesBoxHeight = () => {
        let height = 50 + 20 * codes.length;
        if (height >= 250)
            height = 250;
        return height.toString()
    }

    async function setCorrectErrorMessage(e) {
        setTxError("An unexpected error occured and your transaction did not go through.");
        for (let error of Config.ERRORS.PHASE_1) {
            if (e.message.includes(error.error)) {
                let _msg = error.message;               
                setTxError(_msg)
                return;
            }
        }
    }

    async function getCodes() {
        if (address) {
            let q = query(collection(firestore, "poker_entries"), where("address", "==", address.toLowerCase()))
            const querySnapshot = await getDocs(q);
            let _codes = querySnapshot.docs.map((doc) => {
                return doc.data()
            })
            .filter(_code => {
                let created = _code.createdAt.seconds;
                let expiresAt = created + 72 * 3600
                return dayjs().isBefore(dayjs.unix(expiresAt))
            })
            .sort((a,b) => a.createdAt.seconds - b.createdAt.seconds)
            setCodes(_codes)
        }
    }

    async function getWallet() {        
        if (address) {
            try {                
                const walletDoc = await getDoc(doc(firestore, "wallets", address.toLowerCase()));
                if (walletDoc.exists()) {                   
                    const data = walletDoc.data()                   
                    setWalletDoc(data)
                    setDiscordName(data.discord || "")
                    setPokerclubName(data.pokerclub || "")
                }
                
            } catch (e) {
                console.log(e)
            }            
        }
    }

    useEffect(() => {
        getCodes()
        getWallet()
    }, [address])

    async function onSubmitDiscord() {
        setSaveError();
        if (discordName) {
          if (validateDiscord(discordName)) {
            setIsSavingDiscord(true);
            try {

                const pokerSubmitDiscord = httpsCallable(functions, 'pokerSubmitDiscord');

                let res = await pokerSubmitDiscord({
                    discord: discordName,
                    address: address.toLowerCase()
                })

               if (res && res.data && res.data.success) {
                    console.log("success!")
               } else {
                    setSaveError("Error: Discord username update failed. You cannot update your discord name anymore once you connected with your official account on the Slottery website.");
               }
                            
              setIsSavingDiscord(false);              
            } catch (e) { 
                console.log(e)
            }
          } else {
            setSaveError("Error: Discord username not valid");
          }
        } else {
            setSaveError("Error: Discord username cannot be empty");
        }

        await getWallet()
      }

    async function onSubmitPokerClub() {
        setSaveError();
        if (pokerclubName) {
          if (validatePokerClub(pokerclubName)) {
            setIsSavingPoker(true);
            try {
                const pokerSubmitPokerClub = httpsCallable(functions, 'pokerSubmitPokerClub');

                let res = await pokerSubmitPokerClub({
                    pokerclub: pokerclubName,
                    address: address.toLowerCase()
                })

                if (res && res.data && res.data.success) {
                    console.log("success!")
               } else {
                    setSaveError("Error: Poker club update failed.");
               }

              //setPokerclubName("");
              setIsSavingPoker(false);              
            } catch (e) { 
                console.log(e)
            }
          } else {
            setSaveError("Error: Pokerclub username not valid");
          }
        } else {
            setSaveError("Error: Pokerclub username cannot be empty");
        }

        await getWallet()
      }

    async function generateCode() {
        setTxError(undefined)

        try {

            let wattsBalance = await contractWatts.balanceOf(address);
            let price = ethers.utils.parseEther(Config.DATA.FEE.toString());

            if (!walletDoc || Object.keys(walletDoc).length === 0) {
                setTxError(`Error: Please save your Discord name and Poker Club name above first.`)
                return;
            } 

            if (!walletDoc.discord) {
                setTxError(`Error: Please save your Discord name above first.`)
                return;
            }

            if (!walletDoc.pokerclub) {
                setTxError(`Error: Please save your Poker Club name above first.`)
                return;
            }

            if (wattsBalance.lt(price)) {
                setTxError(`Error: Your WATTS balance is too low. Generating a code requires ${Config.DATA.FEE} WATTS.`)
                return;
            }

            setIsTx(true);
            const tx = await contractPoker.connect(signer).GenerateCode();

            setTxHash(tx.hash);
            let result = await tx.wait();
            let code = getArgsFromEvent(contractPoker, result, "CreateCode", "code");

            let _codes = JSON.parse(JSON.stringify(codes));
            _codes.push({
                createdAt: null,
                code: code[0],
                used: false,
                address
            })
            setCodes(_codes)
        } catch (e) {
            console.log(e)
            await setCorrectErrorMessage(e);            
        }
        setTxHash(undefined);
        setIsTx(false);
    }

    const Loading = () => (
        <>
            <div id="w-node-d5084adc-18c2-8cd3-e678-d41da77696ae-b62521ab" className="text centred" style={{color: "#58d658"}}>Transaction is loading...</div>
            {
                txHash &&

                <>
                    <a id="w-node-_3eb213d0-fd57-540c-fe21-2cb07eac8d05-b62521ab" href={Config.URLS.ETHERSCAN + "/tx/" + txHash} className="link-block w-inline-block" target="_blank">
                        <div className="text bold centred">View transaction on Bscscan.</div>
                    </a>
                    <div data-w-id="531c44b4-0b49-e823-3a34-e7e8535fa824" data-animation-type="lottie" data-src="documents/lottieflow-loading-04-1-ffffff-easey.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="3.1458333333333335" data-duration="0" id="w-node-_531c44b4-0b49-e823-3a34-e7e8535fa824-b62521ab" className="lottie buy-ticket-loading-lottie"></div>
                </>
            }

        </>
    )

    return (
        <>
        {
            isConnected && isCorrectChain ?
            <div className="wrapper redeem-ticket-wrapper">
                <div className="grid redeem-tickets-grid">
                    <div>
                        <div className="text h2">Generate My Poker Game Code.</div>
                        <div className="horizontal-line"></div>
                        <div className="spacer _1em"></div>
                    </div>
                    <div style={{ textAlign: 'left' }} className="text">
                        <p>
                            Burn WATTS to get a poker game code which you can use in Discord to participate in Slotie Poker Games. Each code requires a 100 WATTS generation fee.
                        </p>
                        <p>
                            Before you can generate your game code you need to add a Discord name that will be associated to your wallet with address <i>{address}</i>.
                        </p>
                    </div>

                    <h3 style={{marginBottom: "0px"}}>Enter your accounts</h3>
                    <div style={{ textAlign: 'center' }} className="text">
                        <p style={{margin: "0px"}}>
                            Before you can generate a code you need to enter your Discord and Pokerclub usernames. Discord name should be your full name including the numerical tag <i>(e.x. myusername#1234)</i>. Your Pokerclub username can be anything. You must SAVE both before generating your code.
                        </p>
                    </div>
                    <div className="form-block w-form">
                        <form id="wf-form-Buy-Ticket-Form" name="wf-form-Buy-Ticket-Form" data-name="Buy Ticket Form" className="form">
                            <div className="text max-tickets-text">
                            </div>
                            <input value={discordName} placeHolder="Discord" onChange={(e) => setDiscordName(e.target.value)} type="text" className="text-field w-input" id="Number-of-tickets" required="" style={{width: "200px"}} />
                            {
                                !isSavingDiscord ?
                                    <input value="Save" onClick={() => onSubmitDiscord()} className="button large mint-ticket-form-button w-button" style={{width: "100px"}} />
                                :
                                <>                                    
                                    <Oval
                                        height="40"
                                        width="40"
                                        color='#ffffff'
                                        ariaLabel='loading'                                        
                                    /> 
                                </>
                            }
                                 
                        </form>
                    </div>

                    <div className="form-block w-form">
                        <form id="wf-form-Buy-Ticket-Form" name="wf-form-Buy-Ticket-Form" data-name="Buy Ticket Form" className="form">
                            <div className="text max-tickets-text">
                            </div>
                            <input value={pokerclubName} placeHolder="PokerClub"  onChange={(e) => setPokerclubName(e.target.value)} type="text" className="text-field w-input" id="Number-of-tickets" required="" style={{width: "200px"}} />
                            {
                                !isSavingPoker ?
                                    <input value="Save" onClick={() => onSubmitPokerClub()} className="button large mint-ticket-form-button w-button" style={{width: "100px"}} />
                                :
                                <>                                    
                                    <Oval
                                        height="40"
                                        width="40"
                                        color='#ffffff'
                                        ariaLabel='loading'                                        
                                    /> 
                                </>
                            }
                        </form>
                        {
                            saveError &&
                            <div className="error-message text centred" style={{color: "red"}}>
                                {saveError}
                            </div>
                        }
                    </div>

                        <h3 style={{marginBottom: "0px", marginTop: "20px"}}>My Codes</h3>
                        <div style={{ textAlign: 'center' }} className="text">
                            <p style={{margin: "0px"}}>
                                Warning: codes are only valid for 72 hours after they are created and will disappear after that.
                                Note: if your code is not here please wait for 1 minute and refresh the screen.
                            </p>
                        </div>
                        <div style={{ textAlign: 'center', overflow: "scroll", height: getCodesBoxHeight() + "px", overflowX: "hidden" }} className="text">
                            {
                                codes.map(code => {
                                    return (
                                        <p key={code.code} style={{ marginBottom: "5px", textDecoration: code.used ? "line-through" : "none" }}>
                                            <strong>Code:</strong> {code.code}
                                                { code.createdAt ?  
                                                    <>
                                                        <strong> | Expires at: </strong> {getExpiry(code.createdAt)}
                                                    </>
                                                    : 
                                                    null 
                                                }
                                        </p>
                                    )
                                })
                            }                            
                        </div>

                    {
                        !isTx ?
                        <span 
                            onClick={(e) => { e.preventDefault(); generateCode(); }} 
                            id="w-node-_4013a355-e959-c384-dd5a-1d04264fa9bc-b62521ab" 
                            style={{marginBottom: "0px", marginTop: "20px"}} 
                            className="button large w-button"
                        >
                            Generate Code
                        </span>
                        :
                        <></>
                    }

                    {
                        isTx && !txError ?
                        <Loading />
                        :
                        <></>
                    }

                    {
                        txError &&
                        <div className="error-message text centred" style={{color: "red"}}>
                            {txError}
                        </div>
                    }
                </div>
            </div>
             :
             isConnected && !isCorrectChain ?
                 <StayUpdated 
                     text={"You are not connected to the correct network. Please connect to the Binance Smart Chain network."}
                 />
             :
             <Connect connect={connect} />
        }
        </>
    );
};

export default Home;
