import React, { useState, useEffect } from "react";

const StayUpdated = ({text, link, linkText}) => {
    return (
         <div className="wrapper raffle-coming-soon-wrapper">
            <div className="wrapper partner-heading-wrapper">
            <div className="text h2">{text}</div>
            </div>
            <div className="horizontal-line"></div>
            <div className="spacer _2em"></div>
            {
                link && linkText &&
                <div className="wrapper nav-button-wrapper">
                    <a href={link} target="_blank" className="button secondary small w-inline-block">
                    <div className="text">{linkText}</div>
                    </a>
                </div>
            }            
        </div>
    )
}

export default StayUpdated