import React, { useState, useEffect } from "react";

const Connect = ({ connect }) => {
    return (
        <div className="wrapper connect-to-eth-wrapper">
            <div className="wrapper partner-heading-wrapper">
            <div className="text h3">Connect to Binance Smart Chain</div>
            </div>
            <div className="horizontal-line"></div>
            <div className="spacer _2em"></div>
            <a onClick={(e) => {
                e.preventDefault();
                connect();
            }} href="#" className="button large w-button">Connect</a>
        </div>
    )
}

export default Connect